body {
  margin: 0;
  background-color: #353640;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.header {
  background-color: #3f51b5;
}

.video-list {
  overflow: auto;
  flex: 2;
  max-height: 760px;
  padding: 10px;
  background-color: #222329;
  margin: 20px;
  border-radius: 10px;
}

.video-item {
  border-bottom: 1px #fff solid;
}

.video-index {
  font-style: italic;
  margin-right: 5px;
}

.video-title {
  font-weight: 700;
}

.player {  
  flex: 10;
  max-height: 760px;
  padding: 10px;
  background-color: #222329;
  margin: 20px;
  border-radius: 10px;
}

video {
  margin: 5px;
  max-height: 710px;
}